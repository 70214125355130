import * as React from 'react';
import {Helmet} from "react-helmet";
import hash from 'js-crypto-hash'; // for npm

import Layout from "../components/layout"
import Grid from '@mui/material/Grid'
import GridItem from '../components/GridItem'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import { Paper } from '@mui/material';

export default function Hash() {
  const [targetPlainText, setTargetPlainText] = React.useState("");
  const [outHashText, setOutHashText] = React.useState("");
  const [outHexText, setOutHexText] = React.useState("");
  const [algorithm, setAlgorithm] = React.useState("SHA-256");

  const doHash = () => {
    const msg = Buffer.from(targetPlainText, "utf-8");
    hash.compute(msg, algorithm).then( (digest) => {      
      const buf = Buffer.from(digest);
      setOutHashText(buf.toString("base64"));
      setOutHexText(buf.toString("hex"));
    });
  };

  const handleRadioChange = (event) => {
    setAlgorithm(event.target.value);
  };

  return (
    <Layout>
       <Helmet>
        <title>Online Hash Converter - MD5, SHA1, SHA256, SHA512</title>
        <meta name="keywords" content="online,aes,gcm,cbc,encryptor,decryptor,md5,sha1,sha256,sha-384,sha-512,sha3-224,sha3-256,sha3-384,sha3-512" />
        <meta name="description" content="Hash Converter, free online tool to create an hash from a string" />
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridItem sx={{textAlign:'left'}}>
            <FormControl>
              <FormLabel id="radio-buttons-group-label">Algorithm</FormLabel>
              <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                defaultValue="SHA-256"
                name="row-radio-buttons-group"
                value={algorithm}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="MD5" control={<Radio />} label="MD5" />
                <FormControlLabel value="SHA-1" control={<Radio />} label="SHA-1" />  
                <FormControlLabel value="SHA-256" control={<Radio />} label="SHA-256" />  
                <FormControlLabel value="SHA-384" control={<Radio />} label="SHA-384" />
                <FormControlLabel value="SHA-512" control={<Radio />} label="SHA-512" />
                <FormControlLabel value="SHA3-224" control={<Radio />} label="SHA3-224" />                
                <FormControlLabel value="SHA3-256" control={<Radio />} label="SHA3-256" />
                <FormControlLabel value="SHA3-384" control={<Radio />} label="SHA3-384" />
                <FormControlLabel value="SHA3-512" control={<Radio />} label="SHA3-512" />
              </RadioGroup>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid item xs={12}>
          <GridItem>
            <TextField
              label="Plain Text(UTF-8)"
              multiline
              rows={4}
              fullWidth
              onChange={(event) => { setTargetPlainText(event.target.value); }}
              value={targetPlainText}
            />
            <Button
              fullWidth variant="contained" size="large"
              onClick={doHash}
              sx={{ marginTop: 2 }}
            >
              Hash
            </Button>
            <TextField
              label="Hash Base64"
              multiline
              rows={4}
              fullWidth
              value={outHashText}
              sx={{ marginTop: 2 }}
            />
            <TextField
              label="Hash Hex"
              multiline
              rows={4}
              fullWidth
              value={outHexText}
              sx={{ marginTop: 2 }}
            />
          </GridItem>
        </Grid>
      </Grid>
      <Paper sx={{ p: 5 }}>       
        <h2>What is Hash?</h2>
        <p>Hash is a one-way function that converts a string of an arbitrary length into a fixed-length value. Hash is used to verify the integrity of data. Hash is also used to generate a unique fingerprint of a file or a string. Hash is used in many applications such as digital signature, digital certificate, and blockchain.</p>        
      </Paper>
    </Layout>
  );
}
